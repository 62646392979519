import classNames from "classnames";
import styles from "./TextField.module.scss";
import { ChangeEvent } from "react";

interface TextFieldProps {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  helperText?: any;
  error?: boolean;
  type?: string;
}

export const TextField = ({
  className,
  placeholder,
  helperText,
  error,
  value,
  onChange,
  onBlur,
  type = "text",
  ...props
}: TextFieldProps) => {
  return (
    <div className={styles["textfield-wrapper"]}>
      <input
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        type={type}
        className={classNames(
          styles["textfield__field"],
          { [styles["textfield__field-error"]]: error === true },
          className
        )}
        {...props}
      />
      {error && <p className={styles["textfield__helpertext"]}>{helperText}</p>}
    </div>
  );
};
