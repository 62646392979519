import classNames from "classnames";
import styles from "./TextFieldLabel.module.scss";

interface TextFieldLabelProps {
  className?: string;
  children?: React.ReactNode;
}

export const TextFieldLabel = ({
  className,
  children,
}: TextFieldLabelProps) => {
  return (
    <label className={classNames(styles["textfield-label"], className)}>
      {children}
    </label>
  );
};
