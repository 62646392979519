import classNames from "classnames";
import styles from "./Button.module.scss";

interface ButtonProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  type?: any;
  id?: string;
}

export const Button = ({
  className,
  onClick,
  type = "submit",
  children,
  id
}: ButtonProps) => {
  return (
    <button
      id={`button-${id}`}
      type={type}
      onClick={onClick}
      className={classNames(styles["button"], className)}
    >
      {children}
    </button>
  );
};
