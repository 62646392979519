import { useState } from "react";
import { TextField } from "../TextField";
import classNames from "classnames";
import styles from "./PasswordField.module.scss";
import { EyeIcon, EyeOffIcon } from "../Icons";

interface PasswordFieldProps {
  className?: string;
}

export const PasswordField = (props: any, { className }: PasswordFieldProps) => {
  const [isShowPassword, setShowPassword] = useState(false);

  const onHideShowPassword = () => {
    setShowPassword(!isShowPassword);
  };

  return (
    <div className={classNames(styles["password-field"], className)}>
      <TextField
        {...props}
        type={isShowPassword ? "text" : "password"}
        className={styles["password-field__field"]}
      />
      <div
        onClick={onHideShowPassword}
        className={styles["password-field-icon"]}
      >
        {isShowPassword ? <EyeOffIcon /> : <EyeIcon />}
      </div>
    </div>
  );
};
