import classNames from "classnames";
import styles from "./Text.module.scss";

interface TextProps {
  className?: string;
  children?: React.ReactNode;
}

export const Text = ({className, children}:TextProps) => {
  return (
    <p className={classNames(styles["text"], className)}>
      {children}
    </p>
  )
}