import React, { forwardRef } from "react";
import classNames from "classnames";
import styles from "./Page.module.scss";
import { motion } from "framer-motion";

interface PageProps {
  className?: string;
  children?: React.ReactNode;
}

export const Page = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PageProps>
>(({ className, children }, ref) => {
  return (
    <div ref={ref} className={classNames(styles["page"], className)}>
      {children}
    </div>
  );
});

export const MPage = motion(Page);
