import classNames from "classnames";
import styles from "./Logo.module.scss";
import { LogoIcon } from "../Icons";

interface LogoProps {
  className?: string;
}

export const Logo = ({ className }: LogoProps) => {
  return (
    <div className={classNames(styles["logo"], className)}>
      <div className={styles["logo-icon"]}>
        <LogoIcon />
      </div>
      <div className={styles["logo-text"]}>
        Корпоративный <br /> портал
      </div>
    </div>
  );
};
