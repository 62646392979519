import { CapibaraMobilePic } from "../../Pics/CapibaraPic";
import { Tooltip } from "../../Tooltip";

interface IMobileFigure {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  active?: boolean;
}

export const MobileFigure = ({onMouseEnter, onMouseLeave, active}: IMobileFigure) => {
  return (
    <>
      <CapibaraMobilePic
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Tooltip
        left="0"
        bottom="0"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        active={active}
        text="ЛАНИТ опекает капибару Кузьму в Московском зоопарке"
      />
    </>
  );
};
