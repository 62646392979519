import classNames from "classnames";
import styles from "./Title.module.scss";

interface TitleProps {
  className?: string;
  children?: React.ReactNode;
}

export const Title = ({className, children}:TitleProps) => {
  return (
    <h2 className={classNames(styles["title"], className)}>
      {children}
    </h2>
  )
}