import classNames from "classnames";
import styles from "./Subtitle.module.scss";

interface SubtitleProps {
  className?: string;
  children?: React.ReactNode;
}

export const Subtitle = ({ className, children }: SubtitleProps) => {
  return (
    <p className={classNames(styles["subtitle"], className)}>{children}</p>
  );
};
