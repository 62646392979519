import styles from "../../../pages/Auth/Auth.module.scss";
import { motion, AnimationProps } from "framer-motion";
import { SVGProps } from "react";

const mainPathVariant: AnimationProps = {
  transition: {
    duration: 1.5,
    easy: "easeInOut",
  },
  variants: {
    visible: {
      pathLength: 1,
    },
    hidden: {
      pathLength: 0,
    },
  },
};

const shapeVariations: AnimationProps = {
  transition: {
    delay: 1.5,
    duration: 0.25,
    ease: "easeIn",
  },
  variants: {
    visible: {
      fillOpacity: 1,
    },
    hidden: {
      fillOpacity: 0,
    },
  },
};

export const CapibaraPic = (props: SVGProps<SVGSVGElement>) => {
  const pathColor = "#1F1F1F";
  const shapeColor = "#00AEFF";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="771"
      height="782"
      fill="none"
      viewBox="0 0 771 782"
      className={styles["auth-capibara"]}
      {...props}
    >
      <g clipPath="url(#clip0_6716_60416)">
        <path
          fill="#fff"
          d="M555.292 651.315c-22.371.212-41.336-2.637-47.818-8.458-17.969-16.129-43.416-157.193-2.502-168.046 40.943-10.854 138.84 18.099 138.84 18.099s34.28-20.858 38.984-14.734c4.703 6.124-14.653 21.98-17.155 24.193-2.503 2.213 27.647 65.09 28.491 64.817.844-.273-14.924-8.61-14.924-8.61l6.633 20.039-11.879-9.185s12.603 27.103 19.808 29.043c6.663 1.794 66.633 11.243 73.117 42.511 1.108 5.342 0 32.016-29.665 72.016-9.551 15.409-16.151 29.326-29.295 44"
        ></path>
        <motion.path
          {...mainPathVariant}
          animate="visible"
          initial="hidden"
          stroke={pathColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M555.292 651.315c-22.371.212-41.336-2.637-47.818-8.458-17.969-16.129-43.416-157.193-2.502-168.046 40.943-10.854 138.84 18.099 138.84 18.099s34.28-20.858 38.984-14.734c4.703 6.124-14.653 21.98-17.155 24.193-2.503 2.213 27.647 65.09 28.491 64.817.844-.273-14.924-8.61-14.924-8.61l6.633 20.039-11.879-9.185s12.603 27.103 19.808 29.043c6.663 1.794 66.633 11.243 73.117 42.511 1.108 5.342 0 32.016-29.665 72.016-9.551 15.409-16.151 29.326-29.295 44"
        ></motion.path>
        <motion.path
          {...shapeVariations}
          animate="visible"
          initial="hidden"
          stroke={shapeColor}
          strokeWidth="7"
          d="M702.5 756.5c37.688-46.198 55.055-92.117 48.46-109.854-21.19-56.984-65.045-37-90.865-10-17.378 18.173-28.928 59.764-18.868 59 10.06-.765-17.542-59.857-61.714-56.5-44.173 3.356-64.161 42.467-38.711 83.983 15.043 24.541 34.33 42.028 58.59 54.281"
        ></motion.path>
        <path
          fill="#fff"
          d="M766.341 638.703c11.065 26.709-11.879 80.673-78.812 77.339-66.932-3.335 82.834-44.558 64.959-78.558"
        ></path>
        <motion.path
          {...mainPathVariant}
          animate="visible"
          initial="hidden"
          stroke={pathColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M766.341 638.703c11.065 26.709-11.879 80.673-78.812 77.339-66.932-3.335 82.834-44.558 64.959-78.558M673.737 604.984c-7.115 13.825-32.426 37.029-60.647 41.516"
        ></motion.path>
        <path
          fill="#fff"
          d="M549.277 743.682c28.763-12.794 48.903-28.377 51.948-10.854 3.045 17.523-30.602 34.44-35.878 36.926-5.276 2.486-32.743-5.669-26.924-42.686"
        ></path>
        <motion.path
          {...mainPathVariant}
          animate="visible"
          initial="hidden"
          stroke={pathColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M549.277 743.682c28.763-12.794 48.903-28.377 51.948-10.854 3.045 17.523-30.602 34.44-35.878 36.926-5.276 2.486-32.743-5.669-26.924-42.686M560.116 474.811s13.085 52.872 0 102.956c-13.085 50.083-22.763 73.002-22.763 73.002M501.866 619.786a20.666 20.666 0 005.035 5.214c.784.546 1.598 1.061 2.533 1.274 1.658.363 3.377-.273 4.794-1.274 4.432-3.122-6.181-71.032-9.829-92.254.844 5.488 16.462 74.64 22.612 84.948M620.597 510.706a16.343 16.343 0 006.09 4.578c2.624 1.152 5.91 1.758 9.045 1.394 2.955-.333 5.578-1.485 7.538-2.94 1.96-1.456 3.347-3.153 4.583-4.881"
        ></motion.path>
        <motion.path
          {...shapeVariations}
          animate="visible"
          initial="hidden"
          fill={shapeColor}
          d="M579.984 436.187c-.422-2.152-1.266-4.396-2.984-5.76-1.508-1.182-3.528-1.546-5.427-1.394-4.161.363-7.99 3.304-9.437 7.245-.995 2.668-.935 5.609-1.87 8.277a28.619 28.619 0 00-9.406-8.246c-1.086-.607-2.262-1.152-3.528-1.183-2.985-.121-5.487 2.668-5.94 5.639-.452 2.971.664 5.942 2.111 8.58 4.191 7.67 26.23 20.251 26.23 20.251s12.06-24.314 10.251-33.378v-.031zM753.98 512.555c.151-3.153-.241-6.487-2.141-8.973-1.688-2.153-4.341-3.366-7.025-3.79-5.879-.94-10.251 2.243-13.627 7.185-2.262 3.335-5.156 6.973-7.327 10.368a41.228 41.228 0 00-10.16-14.673c-1.297-1.213-2.744-2.365-4.432-2.88-4.071-1.213-8.503 1.789-10.131 5.73-1.628 3.941-1.115 8.458-.03 12.581 3.166 12.066 31.838 34.167 31.838 34.167s22.462-26.527 23.065-39.715h-.03z"
        ></motion.path>
        <motion.path
          {...mainPathVariant}
          animate="visible"
          initial="hidden"
          fill={pathColor}
          d="M493.093 526.107c3.214 0 5.819-3.868 5.819-8.64s-2.605-8.641-5.819-8.641-5.819 3.869-5.819 8.641 2.605 8.64 5.819 8.64zM521.162 537.445c3.247 0 5.88-6.922 5.88-15.461 0-8.54-2.633-15.462-5.88-15.462s-5.879 6.922-5.879 15.462c0 8.539 2.632 15.461 5.879 15.461z"
        ></motion.path>
        <motion.path
          {...mainPathVariant}
          animate="visible"
          initial="hidden"
          stroke={pathColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M625.993 488.059s12.151-21.434 16.884-20.07c4.734 1.365-3.347 23.678-3.347 23.678"
        ></motion.path>
      </g>
      <defs>
        <clipPath id="clip0_6716_60416">
          <path fill="#fff" d="M0 0H771V782H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const CapibaraMobilePic = (props: SVGProps<SVGSVGElement>) => {
  const pathColor = "#1F1F1F";
  const shapeColor = "#00AEFF";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="292"
      height="377"
      fill="none"
      viewBox="0 0 292 377"
      className={styles["auth-capibara-mobile"]}
      {...props}
    >
      <motion.path
        {...shapeVariations}
        animate="visible"
        initial="hidden"
        stroke={shapeColor}
        strokeWidth="7"
        d="M272.586 217.864c-21.338-56.984-65.5-37-91.5-10-17.5 18.173-29.131 59.764-19 59 10.13-.764-17.664-59.856-62.146-56.5-44.482 3.356-64.61 42.467-38.982 83.984 25.4 41.148 62.811 62.462 115.704 72.534a3.002 3.002 0 002.515-.674L202 346.5l20.5-22.5"
      ></motion.path>
      <path
        fill="#fff"
        d="M74.796 222.315c-22.527.212-41.624-2.637-48.152-8.458-18.095-16.129-43.72-157.193-2.52-168.046C65.354 34.957 163.935 63.91 163.935 63.91s34.521-20.858 39.257-14.734c4.736 6.124-14.755 21.98-17.275 24.193-2.52 2.213 27.84 65.09 28.69 64.817.851-.273-15.028-8.61-15.028-8.61l6.679 20.039-11.962-9.185s12.691 27.103 19.947 29.043c6.71 1.794 67.099 11.243 73.628 42.511 1.116 5.342 0 32.016-29.872 72.016-9.618 15.409-16.265 29.326-29.5 44"
      ></path>
      <motion.path
        {...mainPathVariant}
        animate="visible"
        initial="hidden"
        stroke={pathColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M74.796 222.315c-22.527.212-41.624-2.637-48.152-8.458-18.095-16.129-43.72-157.193-2.52-168.046C65.354 34.957 163.935 63.91 163.935 63.91s34.521-20.858 39.257-14.734c4.736 6.124-14.755 21.98-17.275 24.193-2.52 2.213 27.84 65.09 28.69 64.817.851-.273-15.028-8.61-15.028-8.61l6.679 20.039-11.962-9.185s12.691 27.103 19.947 29.043c6.71 1.794 67.099 11.243 73.628 42.511 1.116 5.342 0 32.016-29.872 72.016-9.618 15.409-16.265 29.326-29.5 44"
      ></motion.path>
      <motion.path
        {...shapeVariations}
        animate="visible"
        initial="hidden"
        stroke={shapeColor}
        strokeWidth="7"
        d="M220.982 325.629c37.952-46.198 57.492-90.246 50.85-107.984-21.338-56.983-65.5-36.999-91.5-9.999-17.5 18.173-29.13 59.764-19 58.999 10.13-.764-17.664-59.856-62.146-56.5-44.482 3.357-64.61 42.468-38.982 83.984 15.15 24.541 34.57 42.028 59 54.281"
      ></motion.path>
      <path
        fill="#fff"
        d="M287.322 209.703c11.142 26.709-11.962 80.673-79.363 77.339-67.401-3.335 83.412-44.558 65.413-78.558"
      ></path>
      <motion.path
        {...mainPathVariant}
        animate="visible"
        initial="hidden"
        stroke={pathColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M287.322 209.703c11.142 26.709-11.962 80.673-79.363 77.339-67.401-3.335 83.412-44.558 65.413-78.558M194.07 175.984c-7.165 13.825-32.653 37.029-61.071 41.516"
      ></motion.path>
      <path
        fill="#fff"
        d="M68.74 314.682c28.963-12.794 49.244-28.377 52.311-10.854 3.066 17.523-30.816 34.44-36.13 36.926-5.313 2.486-32.971-5.669-27.112-42.686"
      ></path>
      <motion.path
        {...mainPathVariant}
        animate="visible"
        initial="hidden"
        stroke={pathColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M68.74 314.682c28.963-12.794 49.244-28.377 52.311-10.854 3.066 17.523-30.816 34.44-36.13 36.926-5.313 2.486-32.971-5.669-27.112-42.686M79.654 45.81s13.176 52.873 0 102.957C66.477 198.85 56.73 221.77 56.73 221.77M20.997 190.786a20.715 20.715 0 005.07 5.214c.79.546 1.61 1.061 2.55 1.274 1.67.363 3.401-.273 4.828-1.274 4.463-3.122-6.224-71.032-9.898-92.254.85 5.488 16.577 74.64 22.77 84.948M140.558 81.706c1.487 1.789 3.491 3.426 6.132 4.578 2.642 1.152 5.951 1.758 9.109 1.394 2.975-.333 5.616-1.485 7.59-2.94 1.973-1.456 3.37-3.153 4.615-4.881"
      ></motion.path>
      <motion.path
        {...shapeVariations}
        animate="visible"
        initial="hidden"
        fill={shapeColor}
        d="M99.662 7.187c-.425-2.152-1.276-4.396-3.006-5.76C95.138.245 93.104-.119 91.19.033c-4.19.363-8.046 3.304-9.503 7.245-1.002 2.668-.941 5.609-1.882 8.277a28.778 28.778 0 00-9.473-8.246c-1.093-.607-2.277-1.152-3.552-1.183-3.006-.121-5.526 2.668-5.981 5.64-.456 2.97.668 5.941 2.125 8.579 4.22 7.67 26.414 20.251 26.414 20.251s12.144-24.314 10.323-33.378v-.03zM274.874 83.555c.151-3.153-.243-6.488-2.156-8.974-1.7-2.152-4.372-3.365-7.074-3.79-5.92-.939-10.323 2.244-13.723 7.186-2.277 3.335-5.192 6.973-7.378 10.368a41.242 41.242 0 00-10.231-14.673c-1.306-1.213-2.763-2.365-4.463-2.88-4.099-1.213-8.562 1.789-10.202 5.73-1.639 3.941-1.123 8.458-.03 12.581 3.188 12.066 32.061 34.167 32.061 34.167s22.619-26.527 23.226-39.715h-.03z"
      ></motion.path>
      <motion.path
        {...mainPathVariant}
        animate="visible"
        initial="hidden"
        fill={pathColor}
        d="M12.162 97.107c3.236 0 5.86-3.868 5.86-8.64s-2.623-8.64-5.86-8.64c-3.236 0-5.86 3.868-5.86 8.64s2.624 8.64 5.86 8.64zM40.428 108.445c3.27 0 5.92-6.922 5.92-15.461 0-8.54-2.65-15.462-5.92-15.462s-5.92 6.922-5.92 15.462c0 8.539 2.65 15.461 5.92 15.461z"
      ></motion.path>
      <motion.path
        {...mainPathVariant}
        animate="visible"
        initial="hidden"
        stroke={pathColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M145.992 59.06s12.236-21.435 17.002-20.07c4.767 1.364-3.37 23.677-3.37 23.677"
      ></motion.path>
    </svg>
  );
};
