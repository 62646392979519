import classNames from "classnames";
import styles from "./Tooltip.module.scss";
import { Text } from "../Text";

interface TooltipProps {
  className?: string;
  text?: string;
  active?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

export const Tooltip = ({
  className,
  text,
  active,
  onMouseEnter,
  onMouseLeave,
  left,
  right,
  top,
  bottom,
}: TooltipProps) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ left: left, right: right, top: top, bottom: bottom }}
      className={classNames(
        styles["tooltip"],
        {
          [styles["tooltip--active"]]: active === true,
        },
        className
      )}
    >
      <Text className={styles["tooltip-text"]}>{text}</Text>
    </div>
  );
};
