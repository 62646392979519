import classNames from "classnames";
import styles from "./LoaderWrapper.module.scss";
import { LoaderIcon } from "../Icons";

interface LoaderWrapperProps {
  className?: string;
}

export const LoaderWrapper = ({className}:LoaderWrapperProps) => {
  return (
    <div className={classNames(styles["loader-wrapper"], className)}>
      <LoaderIcon
        className={styles["loader-wrapper-icon"]}
      />
    </div>
  )
}