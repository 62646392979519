import { SVGProps } from "react";

export const AlertCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="#FF8B00"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M16 29.333c7.364 0 13.334-5.97 13.334-13.333 0-7.364-5.97-13.333-13.334-13.333C8.637 2.667 2.667 8.637 2.667 16c0 7.364 5.97 13.333 13.333 13.333zM16 10.667V16M16 21.333h.014"
    ></path>
  </svg>
);
