import styles from "../../pages/Auth/Auth.module.scss";
import { SVGProps } from "react";

export const MainLine = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1750"
      height="901"
      fill="none"
      viewBox="0 0 1750 901"
      className={styles["auth-line-main"]}
      {...props}
    >
      <path
        stroke="#00AEFF"
        strokeWidth="7"
        d="M0 419.967c25.507-.167 113.225-7.645 156.038-36.213 53.015-25.65 55.016-57.839 50.014-61.36-5.001-3.521-36.01-14.586-63.018 97.573-34.517 143.342-34.01 394.317 247.572 472.275 74.815 16.893 259.048-2.552 349.614-202.516 13.355-29.488 13.018-67.637-18.286-75.882-30.929-8.147-72.12 3.389-84.256 66.654-1 6.706-1.501 19.213 4.501 15.591 7.502-4.526-6.502-40.236-35.51-52.307-29.009-12.071-107.531 3.521-60.518 87.011 47.014 83.491 206.06 74.941 273.08 72.929 67.019-2.012 331.239-20.086 480.139-201.685 141.04-172.011 156.55-450.648 96.53-459.701-56.94-8.588-40.51 143.342 83.52 135.798C1598.4 270.897 1742 73.934 1746 0"
      ></path>
    </svg>
  );
};