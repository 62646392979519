import { useState, useEffect } from "react";
import { TextField } from "../../components/TextField";
import { TextFieldLabel } from "../../components/TextFieldLabel";
import { Button } from "../../components/Button";
import { Title } from "../../components/Title";
import { Subtitle } from "../../components/Subtitle";
import { MPage } from "../../components/Page/Page";
import { Logo } from "../../components/Logo";
import { AlertBox } from "../../components/AlertBox";
import { PasswordField } from "../../components/PasswordField";
import { LoaderWrapper } from "../../components/LoaderWrapper";
import { DesktopFigure, MobileFigure } from "../../components/Figure";
import styles from "./Auth.module.scss";
import axios from "axios";
import { TextMessages } from "../../app/messages";

export const Auth = () => {
  const [login, setLogin] = useState("");
  const [activeTooltip, setActiveTooltip] = useState(false);
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [errorAuth, setErrorAuth] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleChangeLogin = (event: { target: { name: any; value: any } }) => {
    setLogin(event.target.value);
    setErrorAuth(false);
  };

  const handleChangePassword = (event: {
    target: { name: any; value: any };
  }) => {
    setPassword(event.target.value);
    setErrorAuth(false);
  };

  const loginValidation = {
    checkEmptyEmail: (email: string) => {
      email.trim();
      if (email === "" || email === " ") {
        return TextMessages.FIELD_IS_REQUIRED;
      } else if (email !== "") {
        return true;
      }
    },
  };

  const passwordValidation = {
    checkEmptyPassword: (password: string) => {
      if (password === "" || password === " ") {
        return TextMessages.FIELD_IS_REQUIRED;
      } else if (password.length > 0) {
        return true;
      }
    },
  };

  const loginErrorText = submitted && loginValidation.checkEmptyEmail(login);
  const passwordErrorText =
    submitted && passwordValidation.checkEmptyPassword(password);

  const getQueryParams = (url: string) => {
    const paramArr = url.slice(url.indexOf("?") + 1).split("&");
    const params: { [key: string]: string } = {};
    paramArr.map((param) => {
      const [key, val] = param.split("=");
      params[key] = decodeURIComponent(val);
      return param;
    });
    return params;
  };

  const testAuth = () => {
    const encoded = btoa(login + ":" + password);

    setSubmitted(true);

    let param: any = {};
    param.userName = login;
    param.password = password;

    if (login && password) {
      setSubmitted(false);
      axios
        .post("/_logon/default.aspx/LogOn", JSON.stringify(param), {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.d === true) {
            axios({
              method: "get",
              url: "/_windows/default.aspx",
              headers: {
                Authorization: "Basic " + encoded,
              },
            }).then(() => {
              setLoading(true);
              setErrorAuth(false);
              setTimeout(() => {
                window.location.href = getQueryParams(window.location.href)[
                  "ReturnUrl"
                ];
              }, 10);
            });
          } else {
            setError(TextMessages.ERROR_LOGIN_ENTER);
            setErrorAuth(true);
          }
        });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (e: any) => {
      if (e.code === "Enter") {
        const button = document.querySelector("#button-1") as HTMLElement;
        button.click();
      }
    });
  }, []);

  return (
    <MPage
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={styles["auth-page"]}
    >
      <DesktopFigure
        onMouseEnter={() => setActiveTooltip(true)}
        onMouseLeave={() => setActiveTooltip(false)}
        active={activeTooltip}
      />
      <Logo className={styles["auth-page-logo"]} />
      <div className={styles["auth-block"]}>
        <div className={styles["auth-block-form"]}>
          {isLoading && <LoaderWrapper />}
          <div className={styles["auth-text"]}>
            <Title className={styles["auth-text__title"]}>
              Добро пожаловать
            </Title>
            <Subtitle className={styles["auth-text__subtitle"]}>
              Чтобы зайти на Корпоративный портал Mylanit, введите данные вашей
              учетной записи
            </Subtitle>
          </div>
          <div className={styles["auth-form"]}>
            <div className={styles["auth-form-capibara-mobile"]}>
              <MobileFigure
                onMouseEnter={() => setActiveTooltip(true)}
                onMouseLeave={() => setActiveTooltip(false)}
                active={activeTooltip}
              />
            </div>
            <div className={styles["auth-form-fields"]}>
              <div className={styles["auth-form-fields__field"]}>
                <TextFieldLabel className={styles["auth-form__label"]}>
                  Добавьте к логину домен, например{" "}
                  <span>&nbsp;lan\ivanov</span>
                </TextFieldLabel>
                <TextField
                  value={login}
                  onChange={handleChangeLogin}
                  className={styles["auth-form__textfield"]}
                  helperText={loginErrorText}
                  error={!!loginErrorText || errorAuth}
                />
              </div>
              <div className={styles["auth-form-fields__field"]}>
                <TextFieldLabel className={styles["auth-form__label"]}>
                  Пароль
                </TextFieldLabel>
                <PasswordField
                  value={password}
                  onChange={handleChangePassword}
                  type="password"
                  className={styles["auth-form__textfield"]}
                  helperText={passwordErrorText}
                  error={!!passwordErrorText || errorAuth}
                />
              </div>
            </div>
            <Button
              type="submit"
              onClick={testAuth}
              className={styles["auth-form__button"]}
              id="1"
            >
              Войти
            </Button>
            <p className={styles["auth-form__error"]}>{error}</p>
          </div>
        </div>
        <AlertBox className={styles["auth-alert"]} />
      </div>
    </MPage>
  );
};
