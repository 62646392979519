export const EyeOffIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#BEC1C5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.88 9.88a3 3 0 104.24 4.24M10.73 5.08c.422-.052.846-.08 1.27-.08 7 0 10 7 10 7a13.163 13.163 0 01-1.67 2.68"
      ></path>
      <path
        stroke="#BEC1C5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.61 6.61A13.526 13.526 0 002 12s3 7 10 7a9.74 9.74 0 005.39-1.61M2 2l20 20"
      ></path>
    </svg>
  )
}