import classNames from "classnames";
import styles from "./AlertBox.module.scss";
import { AlertCircle } from "../Icons";

interface AlertBoxProps {
  className?: string;
}

export const AlertBox = ({className}:AlertBoxProps) => {
  return (
    <div className={classNames(styles["alert-box"], className)}>
      <div className={styles["alert-box-icon"]}>
        <AlertCircle/>
      </div>
      <p className={styles["alert-box-text"]}>
        Если не удается авторизоваться на портале, напишите на почту <a href="mailto:my@lanit.ru">my@lanit.ru</a>
      </p>
    </div>
  )
}