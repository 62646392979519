import { motion } from "framer-motion";
import styles from "./DesktopFigure.module.scss";
import { lineAnimation } from "../../../utils/animations";
import { MainLine } from "../../../components/Lines";
import { CapibaraPic } from "../../../components/Pics/CapibaraPic";
import { Tooltip } from "../../Tooltip";

interface IDesktopFigure {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  active?: boolean;
}

export const DesktopFigure = ({
  onMouseEnter,
  onMouseLeave,
  active,
  className
}: IDesktopFigure) => {
  return (
    <div className={styles["figure"]}>
      <motion.div custom={4} variants={lineAnimation(3823, 3823, 2)}>
        <MainLine />
      </motion.div>
      <div>
        <CapibaraPic onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
        <Tooltip
          left="30%"
          bottom="25%"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          active={active}
          text="ЛАНИТ опекает капибару Кузьму в Московском зоопарке"
        />
      </div>
    </div>
  );
};