import { SVGProps } from "react";

export const LoaderIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        fill="#BEC1C5"
        fillRule="evenodd"
        d="M64 32c0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0v5.333C17.272 5.333 5.333 17.273 5.333 32c0 14.728 11.94 26.667 26.667 26.667 14.728 0 26.667-11.94 26.667-26.667H64z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}